/*
 * @Description: 个人中心
 * @Author: 琢磨先生
 * @Date: 2022-08-17 05:14:27
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 12:43:26
 */
import http from "./axios_init";
export default {
    /**
     * 获取个人信息
     * @returns
     */
    get_my() {
      return http.get("seller/v1/center");
    },

    /**
     * 用户登录后获取
     * 获取我的api接口权限并存储到本地
     */
    get_apis(){
     return http.get("seller/v1/center/apis");
    }




  };
  