/*
 * @Description:门店
 * @Author: 琢磨先生
 * @Date: 2022-08-19 22:48:56
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-08 15:34:35
 */
import http from "./axios_init";

export default {
  /**
   * 获取全部的门店
   * @returns
   */
  get_shops() {
    return http.post("seller/v1/shop");
  },

  /**
   * 获取我管理的门店
   * @returns 
   */
  get_my_shops(){
    return http.get("seller/v1/center/shops");
  }
};
