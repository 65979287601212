/*
 * @Description: 权限检测判断注入app
 * @Author: 琢磨先生
 * @Date: 2022-12-16 10:50:01
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-10 17:17:40
 */

const install = (app) => {
  /**
   * 通过api地址（url）判断是否拥有请求的权限
   */
  app.config.globalProperties.$power = (url) => {
    // var apis = [];
    // if (store.getters.getApis) {
    //   apis = store.getters.getApis;
    // }
    // return apis.findIndex((o) => o == url) > -1;
    var apis = [];
    var json = localStorage.getItem(
      app.config.globalProperties.$cfg.API_STORE_KEY
    );
    if (json) {
      apis = JSON.parse(json);
    }
    return apis.findIndex((o) => o == url.toLowerCase()) > -1;
  };
};

export default { install };
