/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-12-20 11:57:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-10 17:41:18
 */
import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
import "nprogress/nprogress.css";
import "@/assets/app.css";
import App from "./App.vue";
import router from "./router/router";
import http from "./http/http";
import store from "./store/store";
import * as ElIcons from "@element-plus/icons-vue";
import config from "./plugins/in_config";
import utils from "./plugins/utils";
import power from "./plugins/power";
import ECharts from "vue-echarts";
import { use } from "echarts/core";
// 手动引入 ECharts 各模块来减小打包体积
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, PieChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  LineChart,
  TitleComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
]);

const app = createApp(App);

app.use(ElementPlus, { locale });
app.use(router);
app.use(http);
app.use(store);
app.use(config);
app.use(utils);
app.use(power);

for (const [key, component] of Object.entries(ElIcons)) {
  app.component(key, component);
}
// 全局注册组件
app.component("v-chart", ECharts);

//自定义权限检测指令
app.directive("power", {
  // 绑定元素的父组件挂载时调用
  mounted(el, binding) {
    if (!app.config.globalProperties.$power(binding.value)) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
});

app.mount("#app");
