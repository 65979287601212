<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 10:10:50
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 12:51:37
-->

<template>
  <el-container class="page" v-loading="loading">
    <el-aside class="navigation">
      <div class="logo">
        <img alt="Vue logo" src="@/assets/logo.svg" />
      </div>
      <el-scrollbar>
        <div
          :class="'menu-item' + (item.is_active ? ' active' : '')"
          v-for="item in routes"
          :key="item.meta.id"
        >
          <div class="title" @click="onNav(item)">
            <el-icon size="18px" v-if="item.meta.icon != null && item.meta.icon != ''">
              <component :is="item.meta.icon" />
            </el-icon>
            <span style="margin-left: 5px; font-size: 14px">{{ item.meta.title }}</span>
          </div>
          <div class="sub-menu" v-if="item.children.length > 0">
            <div class="father-title">{{ item.meta.title }}</div>
            <el-menu
              :router="true"
              :default-active="current_meta.id"
              :default-openeds="item.children.map((x) => x.meta.id)"
            >
              <sub-menu
                v-for="item in item.children"
                :key="item"
                :father="item"
                :menus="item.children"
              ></sub-menu>
            </el-menu>
          </div>
        </div>
      </el-scrollbar>
    </el-aside>
    <el-container class="page-content">
      <el-header class="navbar">
        <el-container class="container">
          <div>
            <el-popover
              placement="bottom-start"
              title
              :width="200"
              trigger="click"
              content
              v-if="shop_list.length > 0"
              popper-class="shop_popover"
            >
              <template #reference>
                <div class="shop_wrap">
                  <el-icon :size="20" color="#409EFF">
                    <TakeawayBox />
                  </el-icon>
                  <span class="shop_name">{{ currentShop.name }}</span>
                  <el-icon>
                    <ArrowDown />
                  </el-icon>
                </div>
              </template>
              <div class="shop_body">
                <div
                  :class="`row ${currentShop.id == item.id ? 'active' : ''}`"
                  v-for="item in shop_list"
                  :key="item"
                  @click="shopChange(item)"
                >{{ item.name }}</div>
              </div>
            </el-popover>
            <!-- <el-select class="shop_select" v-model="shopId" placeholder>
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in shop_list"
                :key="item.id"
              ></el-option>
            </el-select>-->
          </div>
          <ul class="nav">
            <!-- <li>
              <a href="#">111</a>
            </li>
            <li class="active">
              <a href="#">2222</a>
            </li>-->
            <li class="nav-item">
              <div class="profile">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <el-avatar :src="user.avatar_pic ? user.avatar_pic : avatar"></el-avatar>
                    <label>{{ user.chs_name }}</label>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <router-link :to="{ path: '/profile' }">
                        <el-dropdown-item icon="el-icon-user">个人中心</el-dropdown-item>
                      </router-link>
                      <el-dropdown-item icon="el-icon-switch-button" @click="logout">退出</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </li>
          </ul>
        </el-container>
      </el-header>
      <!-- <el-scrollbar class="base-scrollbar"> -->
      <el-main :class="has_children ? 'zoom' : ''">
        <router-view></router-view>
      </el-main>
      <!-- </el-scrollbar> -->
    </el-container>
  </el-container>
</template>

<script>
import avatar from "@/assets/avatar.png";
import SubMenu from "./submenu";
import Cookies from "js-cookie";
import shop_api from "@/http/shop_api";
import center_api from "../http/center_api";

export default {
  name: "layout",
  components: { SubMenu },
  data() {
    return {
      loading: false,
      //本地默认头像
      avatar: avatar,
      user: {
        avatar_pic: "",
      },
      routes: [],
      //激活的menu
      // activeIndex: "",
      current_meta: {},
      //标识当前打开顶级菜单是否有子对象
      has_children: false,
      //显示门店
      shopVisible: false,
      //我管理的门店
      shop_list: [],
      //当前门店
      currentShop: {},
    };
  },
  watch: {
    /**
     * 监听路由变化
     */
    $route() {},
    /**
     * 监听个人资料变化
     */
    getMy(data) {
      this.user = data;
    },
  },
  created() {
    this.loading = true;
    var json = localStorage.getItem(this.$cfg.CURRENT_META_KEY);
    if (json) {
      this.current_meta = JSON.parse(json);
    }
    this.$http
      .get("/seller/v1/center")
      .then((res) => {
        if (res.code == 0) {
          this.$store.commit("setMy", res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    /**
     * 获取我的菜单数据
     */
    this.$http.get("/seller/v1/center/vue_route").then((res) => {
      if (res.code == 0) {
        this.routes = res.data;
        this.routes.forEach((item) => {
          item.is_active = false;
          if (this.current_meta.father_set) {
            var array = this.current_meta.father_set.split(",");
            item.is_active = array.findIndex((x) => x == item.meta.id) > -1;
            if (item.is_active) {
              this.has_children = true;
            }
          } else {
            item.is_active = this.current_meta.id == item.meta.id;
          }
        });
        this.routes.splice(0, 0, {
          redirect: "/dashboard",
          meta: {
            title: "首页",
            icon: "HomeFilled",
            id: "",
          },
          is_active: this.$route.path == "/dashboard",
          children: [],
        });
      }
      this.loading = false;
    });

    /**
     * 获取我的接口权限数据
     */
    center_api.get_apis().then((res) => {
      if (res.code == 0) {
        if (res.data) {
          localStorage.setItem(
            this.$cfg.API_STORE_KEY,
            JSON.stringify(res.data)
          );
          this.initNotification();
        }
      }
    });

    /**
     * 获取系统配置参数
     */
    this.$http.get("seller/v1/common/config").then((res) => {
      if (res.code == 0) {
        this.$store.commit("setSettings", res.data);
      }
    });
    this.loadShop();
  },

  methods: {
    /**
     * 获取我管理的门店
     */
    loadShop() {
      var shopId = localStorage.getItem(this.$cfg.CURRENT_SHOP_KEY);
      shop_api.get_my_shops().then((res) => {
        if (res.code == 0) {
          this.shop_list = [
            // {
            //   id: "0",
            //   name: "全部门店",
            // },
          ];
          res.data.forEach((x) => {
            this.shop_list.push({
              id: x.id,
              name: x.name,
            });
          });
          if (this.shop_list.length > 0) {
            var item = this.shop_list.find((o) => o.id == shopId);
            if (!item) {
              item = this.shop_list[0];
              localStorage.setItem(this.$cfg.CURRENT_SHOP_KEY, item.id);
            }
            this.currentShop = item;
          } else {
            localStorage.setItem(this.$cfg.CURRENT_SHOP_KEY, "");
          }
        }
      });
    },

    /**
     * 门店选择切换
     */
    shopChange(item) {
      this.currentShop = item;
      this.shopVisible = false;
      localStorage.setItem(this.$cfg.CURRENT_SHOP_KEY, item.id);
      window.location.reload();
    },

    /**
     * 退出登录
     */
    logout() {
      this.$http.get("seller/v1/center/out").then((res) => {
        if (res.code == 0) {
          Cookies.remove(this.$cfg.TOKEN);
          this.$router.push({
            name: "login",
            replace: true,
          });
        }
      });
    },
    /**
     * 顶级导航切换
     */
    onNav(item) {
      if (item.path) {
        this.$router.push(item.path);
      } else {
        this.$router.push(item.redirect);
      }
      this.routes.map((x) => {
        x.is_active = false;
      });
      item.is_active = true;
      //存在子路由
      this.has_children = item.children.length > 0;
      item.children.forEach((x) => {
        if (x.children.length > 0) {
          x.children.forEach((child) => {
            if (child.path == item.redirect) {
              this.current_meta = child.meta;
            }
          });
        } else {
          if (x.path == item.redirect) {
            this.current_meta = x.meta;
          }
        }
      });
    },

    /**
     * 初始化通知
     */
    initNotification() {
      Notification.requestPermission().then((permission) => {
        if (permission != "granted") {
          this.$notify({
            title: "错误",
            message:
              "您已禁用浏览器消息通知，请到设置开启允许Web Notification通知！",
            type: "error",
          });
        }
      });
      if (this.$power("seller/v1/alert/order")) {
        setInterval(() => {
          this.$http.get("seller/v1/alert/order").then((res) => {
            if (res.code == 0) {
              if (res.data) {
                var item = res.data;
                new Notification("订单提醒", {
                  tag: "order",
                  lang: "zh-CN",
                  renotify: true,
                  body: `${item.shop_name}\r\n${item.contact_name}、${item.order_no}`,
                });
              }
            }
          });
        }, 20000);
      }
      if (this.$power("seller/v1/alert/order/refund")) {
        setInterval(() => {
          this.$http.get("seller/v1/alert/order/refund").then((res) => {
            if (res.code == 0) {
              if (res.data) {
                var item = res.data;
                new Notification("有新的 退款单 请及时处理", {
                  tag: "refund",
                  lang: "zh-CN",
                  renotify: true,
                  body: `${item.shop_name}\r\n${item.contact_name}、${item.order_no}`,
                });
              }
            }
          });
        }, 60000);
      }
    },
  },
};
</script>


<style >
.navigation.el-aside,
.navigation .el-scrollbar {
  overflow: visible;
}
.sub-menu .el-sub-menu__title {
  padding: 0;
  height: 48px;
  line-height: 48px;
}
.sub-menu .el-menu.el-menu--inline {
  padding: 0 10px;
}
.sub-menu .el-sub-menu__title {
  color: #111 !important;
}
</style>

<style scoped>
/* 头部 */
.page .el-header {
  background-color: white;
  border-bottom: 1px solid var(--border-color);
}

.navbar .container {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navbar .container::after {
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ebedf1;
  display: block;
}

.navbar ul.nav {
  height: 100%;
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
  height: 100%;
}

.navbar ul.nav li {
  list-style: none;
  height: 100%;
}

.navbar .nav a {
  color: #4d5164;
  height: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 22px;
  position: relative;
}

.navbar .nav li.active a {
  color: #409eff;
}

.navbar .nav li.active a:after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: calc(50% - 15px);
  width: 30px;
  height: 2px;
  background: #409eff;
}

.navbar .nav li .profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.navbar .nav .profile .el-dropdown-link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar .nav li .el-dropdown .el-avatar {
  margin-right: 4px;
}

/* 左侧导航 */
.page .el-aside {
  height: 100%;
  background: #545c64;
  width: 100px;
  display: flex;
  flex-direction: column;
}

.el-main {
  display: flex;
  flex-direction: column;
}
.el-main.zoom {
  margin-left: 140px;
}

.page .el-aside .logo {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.page .el-aside .logo img {
  width: 80%;
}

.page .el-aside .el-scrollbar {
  height: 1px;
  flex-grow: 1;
}

.el-aside .menu-item {
  display: flex;
  height: 48px;
  border-radius: 10px 0 0 10px;
  margin-left: 10px;
  flex-direction: row;
  align-items: center;
  color: white;
  cursor: pointer;
}

.el-aside .menu-item .title {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.el-aside .menu-item .sub-menu {
  position: absolute;
  right: -140px;
  width: 140px;
  background: white;
  z-index: 2;
  top: 0;
  height: 100%;
  border-right: 1px solid var(--border-color);
}

.el-aside .menu-item.active {
  background: white;
}
.el-aside .menu-item.active .title {
  color: var(--el-color-primary);
}
/* 子菜单 */
.el-aside .menu-item .sub-menu {
  display: none;
}
.el-aside .menu-item.active .sub-menu {
  display: block;
}

.sub-menu .father-title {
  margin-bottom: 10px;
  font-size: 20px;
  color: #333;
  font-weight: 600;
  padding-top: 15px;
  padding-left: 20px;
}

/* 门店选择 */
.shop_popover.el-popper {
  padding: 0 !important;
}
.shop_wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.shop_wrap .shop_name {
  margin: 0 5px;
  font-size: 14px;
  font-weight: bold;
}
.shop_popover .shop_body .row {
  padding: 10px;
  cursor: pointer;
}
.shop_popover .shop_body .row:hover {
  background: #d9ecff;
}
.shop_popover .shop_body .row.active {
  background: #d9ecff;
}
</style>
<style>
.shop_popover.el-popper {
  padding: 0 !important;
}
</style>