/*
 * @Description: 配置参数
 * @Author: 琢磨先生
 * @Date: 2022-05-09 21:46:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-10 15:21:40
 */


export default {
  /**
   * api请求host地址
   */
  HOST:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5000"
      : "https://api.liangtingkeji.com",
  /**
   * 本地缓存token名称
   */
  TOKEN: "emp.token",

  /**
   * 项目app key
   */
  APP_KEY: "pms_18077edfc6a",
  //接口Key
  API_KEY:"pms_1866c7fc6b0",
  /**
   * 登录账号本地存储key
   */
  LOGIN_ACCOUNT_KEY: "emp.account.key",
  /**
   * 当前路由对应的route meta 的cookies key
   */
  CURRENT_META_KEY: "emp.current.meta",

  /**
   * 当前选择的产品类目存储key
   */
  CURRENT_CATEGORY_KEY: "current_category_key",

  //当前选择的门店
  CURRENT_SHOP_KEY: "current_shop_key",

  //API权限数据本地存储Key
  API_STORE_KEY:'api_store_key',
};
 