<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 23:31:35
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-19 05:49:38
-->
<template>
  <el-sub-menu :index="father.meta.id" v-if="menus.length > 0">
    <template #title>
      <el-icon v-if="father.meta.icon != null && father.meta.icon != ''">
        <component :is="father.meta.icon" />
      </el-icon>
      <span style="font-size:16px;">{{ father.meta.title }}</span>
    </template>
    <el-menu-item
      :index="sub.meta.id"
      v-for="sub in father.children"
      :key="sub.meta.id"
      :route="{ path: sub.path }"
      >{{ sub.meta.title }}</el-menu-item
    >
    <!-- <sub-menu
      v-for="item in father.children"
      :key="item"
      :father="item"
      :menus="item.children"
    ></sub-menu> -->
  </el-sub-menu>

  <el-menu-item
    class="only"
    v-if="menus.length == 0"
    :index="father.meta.id"
    :route="{ path: father.path }"
  >
    <el-icon v-if="father.meta.icon != null && father.meta.icon != ''">
      <component :is="father.meta.icon" />
    </el-icon>

    {{ father.meta.title }}</el-menu-item
  >
</template>
<script>
export default {
  name: "SubMenu",
  data() {
    return {};
  },
  props: ["father", "menus"],
  watch: {
    menus: {
      handler() {
        console.log("key", this.menus);
      },
    },
  },
  methods: {},
};
</script>


<style scoped>
  
/* 子菜单 */
.el-aside .menu-item .sub-menu {
  display: none;
}
.el-aside .menu-item.active .sub-menu {
  display: block;
}
 
 .el-menu-item {
  min-width: auto;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  margin: 5px 0px;
  padding-left: 20px !important;
  color: #666;
}

 .el-menu-item.is-active {
  background-color: var(--el-menu-hover-bg-color);
  color: var(--el-menu-active-color);
}
.el-menu-item:hover {
  background-color: var(--el-menu-hover-bg-color);
}

.el-menu-item.only {
  margin: 5px 10px;
}
</style>