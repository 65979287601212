<!--
 * @Description: layout2 
 * @Author: 琢磨先生
 * @Date: 2022-05-08 12:54:25
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-09 15:00:59
-->
<template>
  <el-container>
    <el-aside>left</el-aside>
    <el-container>
      <el-scrollbar>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-scrollbar>
    </el-container>
  </el-container>
</template>

<script>
export default {
  watch: {
    /**
     * 监听路由变化
     */
    $route() {
      // this.getBreadcrumb();
      console.log(this.$router.getRoutes());
    },
  },
  created() {
    // this.getBreadcrumb();
  },
  methods: {
    // /**
    //  * 获取breadcrumb
    //  */
    // getBreadcrumb() {
    //   if (
    //     this.$route.matched &&
    //     Object.keys(this.$route.matched[0].meta).length > 0
    //   ) {
    //     this.breadList = this.$route.matched;
    //   } else {
    //     this.breadList = [];
    //   }
    // },
  },
};
</script>

<style scoped>
.el-aside {
  width: 120px;
  background: white;
  padding: 10px;
}
</style>